//
// _buttons.scss
//
//Buttons
@each $name, $value in $theme-colors {
  .btn-soft-#{$name} {
    background-color: rgba($value, 0.05) !important;
    border: 1px solid rgba($value, 0.05) !important;
    color: #{$value} !important;
    box-shadow: 0 3px 5px 0 rgba($value, 0.1);
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: #{$value} !important;
      border-color: #{$value} !important;
      color: $white !important;
    }
  }
}

.btn {
  padding: 8px 20px;
  outline: none;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 0.5px;
  transition: all 0.3s;
  font-weight: 600;
  border-radius: 6px;
  &:focus {
    box-shadow: none !important;
  }
  &.btn-sm {
    padding: 7px 16px;
    font-size: 10px;
  }
  &.btn-lg {
    padding: 14px 30px;
    font-size: 16px;
  }
  &.searchbtn {
    padding: 6px 20px;
  }
  &.btn-pills {
    border-radius: 30px;
  }
  &.btn-light {
    border: 1px solid darken($light, 2%);
  }
  &.btn-outline-light {
    border-color: $gray-200 !important;
  }
  &.btn-soft-light {
    color: $gray-400 !important;
    border-color: $gray-200 !important;
  }
  &.btn-soft-dark {
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      color: $gray-400 !important;
      border-color: $gray-200 !important;
    }
  }
  &.btn-dark,
  &.btn-secondary {
    color: $gray-200;
  }
  &.btn-outline-light {
    color: $gray-900;
  }
  &.btn-icon {
    height: 36px;
    width: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    .icons {
      height: 16px;
      width: 16px;
      font-size: 16px;
    }
    &.btn-lg {
      height: 48px;
      width: 48px;
      line-height: 46px;
      .icons {
        height: 20px;
        width: 20px;
        font-size: 20px;
      }
    }
    &.btn-sm {
      height: 30px;
      width: 30px;
      line-height: 28px;
    }
  }
}

button:not(:disabled) {
  outline: none;
}

/* ---------------------------------
   Global Classes
---------------------------------- */

.primary-text {
  color: #3a7cc8;
}

.primary-btn {
  background-color: #3a7cc8;
  color: #fff;
}

/* ---------------------------------
   Existing Media Queries
---------------------------------- */

@media (max-width: 425px) {
  #JLogo {
    display: none;
  }

  #headlin {
    padding-bottom: 2rem;
  }

  #home {
    padding-top: 6rem;
  }
}

@media (min-width: 425px) {
  #MobileLogo {
    display: none;
  }

  #home {
    padding-top: 10rem;
  }

  #app-store-col {
    text-align: end;
  }

  #silvair-col {
    text-align: start;
  }
}

/* ---------------------------------
   Videos Section (Scoped Styles)
---------------------------------- */
.videos-section {
  /* ------------------------------
     Headline Section
  ------------------------------ */
  .headline-container {
    margin: 0 auto;
    width: 87.5vw;
    max-width: 1680px;
    padding-top: 5rem;
    padding-bottom: 3rem;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-out, transform 1s ease-out;
    background-color: black !important; /* Force black background */

    /* Make sure there's enough space for the black bg to be visible */
    min-height: 200px;
  }

  .headline-container.visible {
    opacity: 1;
    transform: translateY(0);
  }

  .headline {
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
      sans-serif;
    margin: 0;
    padding: 0;
    color: rgb(29, 29, 31);
    max-width: 75%;
    font-size: 50px;
    line-height: 1.05;
    font-weight: 600;
    letter-spacing: -0.015em;
  }

  /* ------------------------------
     Lifted Section (Card Wrapper)
  ------------------------------ */
  .lifted-section {
    position: relative;
    width: 100%;
    background-color: white;
    border-radius: 0px;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    overflow: hidden;
    z-index: 1;
  }

  /* ------------------------------
     Horizontal Card Scroller
  ------------------------------ */
  .card-container {
    display: flex;
    overflow-x: auto;
    padding: 20px;
    padding-left: 15rem;
    gap: 20px;
    scroll-padding-left: 15rem;
    scrollbar-width: none; /* Firefox */
    background-color: #f7f9fb;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari */
    }
  }

  /* ------------------------------
     Individual Cards
  ------------------------------ */
  .card {
    flex: 0 0 auto;
    width: calc((65vh * 9) / 16); /* 16:9 ratio based on 65% viewport height */
    height: 60vh;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    scroll-snap-align: start;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:hover {
      transform: scale(1.009);
    }
  }

  .card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1;
    transition: background-color 0.3s ease;
  }

  .card:hover .card-overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .card-content {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    z-index: 2;
    color: white;
    font-family: 'Roboto', sans-serif;

    h2 {
      font-size: 1.2em;
      margin: 0 0 10px;
      font-weight: 400;
    }

    p {
      margin: 0;
      font-size: 1.6em;
      font-weight: 700;
    }
  }

  /* Light variant if needed */
  .card-light {
    .card-content {
      color: black;

      h2,
      p {
        color: black;
      }
    }

    &:hover .card-overlay {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  /* Floating circle button on each card */
  .card-button {
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 35px;
    height: 35px;
    background-color: #4a4a4a;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    transition: background-color 0.3s ease;

    span {
      color: white;
      font-size: 10px;
      font-weight: bold;
      line-height: 1;
      display: inline-block;
      width: 20px;
      height: 20px;
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        background-color: white;
        border-radius: 2px;
      }

      &::before {
        width: 100%;
        height: 3px;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      &::after {
        height: 100%;
        width: 2.5px;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      }
    }

    &:hover {
      background-color: #6a6a6a;
    }
  }

  /* ------------------------------
     Modal
  ------------------------------ */
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-body {
    background-color: #fff;
    width: 80%;
    max-width: 800px;
    border-radius: 12px;
    padding: 2rem;
    position: relative;
    max-height: 90vh; /* Allow scrolling if content is tall */
    overflow-y: auto;
  }

  .modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    font-size: 1.5rem;
  }

  .video-frame {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 ratio */
    height: 0;
    overflow: hidden;
    margin-top: 1.5rem;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .presentation-content {
    margin-top: 2rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: #555;

    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      margin-top: 1rem;
      color: #1769b2;
    }

    p,
    ul {
      margin-top: 0.5rem;
      line-height: 1.5;
    }

    ul {
      padding-left: 1.25rem;

      li {
        margin-bottom: 0.75rem;
      }
    }
  }

  /* --------------------------------
     ADDITIONAL RESPONSIVE TWEAKS
     (Adjust as needed)
  -------------------------------- */

  /* Tablet Breakpoint */
  @media (max-width: 768px) {
    .headline {
      font-size: 32px;
    }

    .card-container {
      padding-left: 1rem;
      gap: 1rem;
    }

    .card {
      width: 80vw; /* More flexible for smaller screens */
      height: 50vh;
    }

    .modal-body {
      width: 90%;
      padding: 1rem;
    }
  }

  /* Mobile Breakpoint */
  @media (max-width: 425px) {
    /* Headline adjusts again for small phones */
    .headline {
      font-size: 24px;
    }

    /* Trim padding & gaps further */
    .card-container {
      padding-left: 1rem;
      gap: 0.75rem;
    }

    /* Scale cards for small screens */
    .card {
      width: 85vw;
      height: 45vh;
    }

    /* Ensure modal content fits well on phones */
    .modal-body {
      width: 95%;
      padding: 1rem;
    }

    .modal-close {
      font-size: 2rem; /* Easy to tap */
    }
  }
}
